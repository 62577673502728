import React from "react";
import { SEO, PageLayout, ContactForm } from "@bluefin/components";
import { Header, Grid, Image, h2, p } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class PartyBookingPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
        />
        <PageLayout
          hero={false}
          subfooter={false}
          className={"party-page custom-page"}
        >
          <div className={"booking-header-container"}>
            <Header as={"h1"} className={"booking-header"}>
              Party Booking
            </Header>
            <span className={"booking-subheader"}>
              Thank you for your interest in booking your next party with
              Bennie's Pizza Pub!
            </span>
          </div>
          <Grid stackable={true} columns={1} centered={true}>
            <Grid.Column textAlign={"center"} width={10}>
              <p>WE HAVE 2 GREAT SPACES AVAILABLE FOR PARTIES OF 15 OR MORE:</p>
              <Grid
                stackable={true}
                verticalAlign={"middle"}
                textAlign={"left"}
                centered={true}
                column={"2"}
              >
                <Grid.Row stretched={true}>
                  <Grid.Column width={8} textAlign={"center"}>
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/2e1376fa-0d04-49e4-bea2-1d9351a37203/IMG_9373.jpg"
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={8} textAlign={"right"}>
                    <h2>BENNIE’S BACK ROOM</h2>
                    <p>
                      Semi-private indoor back room that seats up to 20 people
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched={true}>
                  <Grid.Column width={8} textAlign={"left"}>
                    <h2>BENNIE’S COURTYARD</h2>
                    <p>
                      A fully enclosed heated/cooled courtyard that can be
                      opened to Main Street during warmer months, complete with
                      full bar and TV. accommodates groups of 20-50.
                    </p>
                    <p className={"italic-small"}>
                      *Subject to additional rental fee on weekends
                    </p>
                  </Grid.Column>
                  <Grid.Column width={8} textAlign={"center"}>
                    <Image
                      size={"large"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/2e1376fa-0d04-49e4-bea2-1d9351a37203/courtyard.jpg"
                      }
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid>
          <Grid stackable={true} columns={1} centered={true}>
            <Grid.Column textAlign={"center"} width={10}>
              <p className={"italic-case"}>
                We can also take table reservations for parties of 8 or more in
                the main dining room area or back patio in warmer months!
              </p>
              <h2>BOOKING POLICIES</h2>
              <p>
                Cancellations must be done no less than 24 hours in advance.
              </p>
              <p>
                *On weekends, Bennie's Courtyard has an additional $100 rental
                fee that is non-refundable and requires you purchase food or
                drink.
              </p>
              <h2>HOW TO BOOK</h2>
              <p>Fill out the request form below to get started!</p>
              <p className={"italic-case"}>
                Please note that submitting this form does not guarantee your
                reservation. Someone will contact you within 48 hours to confirm
                and go over details.
              </p>
            </Grid.Column>
          </Grid>
          <Grid stackable={true} columns={1} centered={true}>
            <Grid.Column textAlign={"left"} width={8}>
              <ContactForm
                constructMessage={({
                  name,
                  email,
                  phone,
                  numberParty,
                  wichRoom,
                  occasion,
                  drink,
                  preferredDate,
                  preferredTime,
                }) =>
                  `Someone has reached out to you via your website Party form: Name: ${name}, Email: ${email}, Phone: ${phone}, Number In Party: ${numberParty}, Which Room?: ${wichRoom}, Occasion: ${occasion},  Drink Packages?: ${drink}, Preferred Date: ${preferredDate}, Preferred Time: ${preferredTime}`
                }
                fields={[
                  {
                    label: "name",
                    placeholder: "Full Name",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "email",
                    placeholder: "Email",
                    type: "input",
                    inputType: "email",
                    required: true,
                  },
                  {
                    label: "phone",
                    placeholder: "Phone Number",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "numberParty",
                    displayLabel: "Number in Party",
                    placeholder: "Number in Party",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "preferredDate",
                    displayLabel: "Preferred Date",
                    type: "input",
                    inputType: "date",
                    required: true,
                  },
                  {
                    label: "preferredTime",
                    displayLabel: "Preferred Time",
                    type: "input",
                    inputType: "time",
                    required: true,
                  },
                  {
                    label: "wichRoom",
                    displayLabel: "Which Room?",
                    placeholder: "Which Room?",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "occasion",
                    displayLabel: "Occasion",
                    placeholder: "Occasion",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "drink",
                    displayLabel:
                      "Are you interested in food or drink packages? Available",
                    type: "select",
                    required: true,
                    options: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                  },
                ]}
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={""}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                destinationEmail={"partybookings@benniespizzapub.net"}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        _id
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(title: { eq: "Party Booking" }) {
      title
    }
  }
`;
